





















































































import { Vue, Component } from "vue-property-decorator";
const Props = Vue.extend({
  props:[
    "detailStatus"
  ]
});
@Component
export default class TableDetails extends Props{
  formRules = {
    total: {
      label: "lbl_total",
      name: "total",
      placeholder: "lbl_type_here",
      decorator: [
        "total",
        {
          initialValue: 0,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    prepaymentUsed: {
      label: "lbl_prepayment_used",
      name: "prepaymentUsed",
      placeholder: "lbl_type_here",
      decorator: [
        "prepaymentUsed",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    reminingPrepayment: {
      label: "lbl_remaining_amount",
      name: "reminingPrepayment",
      placeholder: "lbl_type_here",
      decorator: [
        "reminingPrepayment",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    prepaymentPaid: {
      label: "lbl_prepayment_paid",
      name: "prepaymentPaid",
      placeholder: "lbl_type_here",
      decorator: [
        "prepaymentPaid",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    paidStatus: {
      label: "lbl_paid_status",
      name: "paidStatus",
      placeholder: "lbl_choose",
      decorator: [
        "paidStatus",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
  };
  clickStatus(type): void {
    this.detailStatus(type);
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
}
