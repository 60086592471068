var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16], type: "flex" } },
    [
      _c(
        "a-col",
        { attrs: { span: 16 } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.total.label) } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.total.decorator,
                    expression: "formRules.total.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.total.name,
                  placeholder: _vm.$t(_vm.formRules.total.placeholder),
                  autocomplete: "off",
                  disabled: true
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.prepaymentUsed.label) } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 22 } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.prepaymentUsed.decorator,
                            expression: "formRules.prepaymentUsed.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.prepaymentUsed.name,
                          placeholder: _vm.$t(
                            _vm.formRules.prepaymentUsed.placeholder
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "pl-4", attrs: { span: 2 } },
                    [
                      _c("a-icon", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { type: "more" },
                        on: {
                          click: function($event) {
                            return _vm.clickStatus("prepaymentUsed")
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: { label: _vm.$t(_vm.formRules.reminingPrepayment.label) }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.reminingPrepayment.decorator,
                    expression: "formRules.reminingPrepayment.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.reminingPrepayment.name,
                  placeholder: _vm.$t(
                    _vm.formRules.reminingPrepayment.placeholder
                  ),
                  autocomplete: "off",
                  disabled: true
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.prepaymentPaid.label) } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 22 } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.prepaymentPaid.decorator,
                            expression: "formRules.prepaymentPaid.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.prepaymentPaid.name,
                          placeholder: _vm.$t(
                            _vm.formRules.prepaymentPaid.placeholder
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "pl-4", attrs: { span: 2 } },
                    [
                      _c("a-icon", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { type: "more" },
                        on: {
                          click: function($event) {
                            return _vm.clickStatus("prepaymentPaid")
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.paidStatus.label) } },
            [
              _c("a-select", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.paidStatus.decorator,
                    expression: "formRules.paidStatus.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.paidStatus.name,
                  placeholder: _vm.$t(_vm.formRules.paidStatus.placeholder),
                  "show-search": true,
                  "option-filter-prop": "children",
                  "filter-option": _vm.filterOption,
                  "allow-clear": true,
                  disabled: true
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }